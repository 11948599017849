<template>
  <div @click="logout">
    <feather-icon class="mr-50" icon="LogOutIcon" size="16"/>
    <span>Se déconnecter</span>
  </div>
</template>

<script>
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
  DATA_REMOVAL_MUTATION,
  REFRESH_TOKEN_GETTER
} from "@/modules/authnz/models/store";
import authenticationConfig from "@/configuration/authentication-config";
import {AUTHNZ_ROUTES_NAMES} from "@/router/authnz";
import {useApplicationContext} from "@/shared/composables/use-application-context";


export default {
  name: 'logout-button',
  setup() {
    const {logger, httpClient, router, store} = useApplicationContext();

    function cleanUserAuthDataAndRedirect(routeName) {
      logger.debug(`Removing any user data from authentication store`);
      store.commit(`${AUTH_STORE_NAMESPACE}/${DATA_REMOVAL_MUTATION}`);

      logger.debug(`Redirecting to login page using route name ${routeName}`);
      router.push({name: routeName});
    }

    const logout = async () => {
      try {
        logger.debug(`Retrieving current user information and tokens`);
        let user = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
        let refreshToken = store.getters[`${AUTH_STORE_NAMESPACE}/${REFRESH_TOKEN_GETTER}`];

        if (!refreshToken) {
          logger.error('No refresh token is available to perform user logout');
          cleanUserAuthDataAndRedirect(AUTHNZ_ROUTES_NAMES.AUTHN_ERRORS);
          return;
        }

        logger.debug(`Signing out user ${JSON.stringify(user)}`);
        await httpClient.post(`${authenticationConfig.logoutEndpoint}`, {refresh_token: refreshToken});
        logger.debug(`User ${user?.id} successfully signed out, finalizing logout`);

        // TODO use CASL ability to reset abilities
        // e.g.
        // this.$ability.update(initialAbility);

        await cleanUserAuthDataAndRedirect(AUTHNZ_ROUTES_NAMES.LOGIN);
      } catch (error) {
        logger.error(`Logout error, cleaning up and redirecting to login page anyway`, error);
        await cleanUserAuthDataAndRedirect(AUTHNZ_ROUTES_NAMES.AUTHN_ERRORS);
      }
    };

    return {logout};
  },
};
</script>
